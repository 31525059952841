/* Base styles and resets */
:root {
    --primary-color: rgb(168, 54, 99);
    --primary-light: rgba(168, 54, 99, 0.03);
    --text-primary: #333333;
    --text-secondary: #666666;
    --sidebar-width: 240px;
    --topbar-height: 84px;
  }
body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
}

/* Layout */
.app-container {
    display: grid;
    grid-template-columns: 1fr 40%;
    height: 100vh;
}

/* Menu Bar */
.menu-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 65px;
    background: #1a1a1a;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    z-index: 20;
    border-bottom: 1px solid #333;
}

.nav-group {
    display: flex;
    align-items: left;
    gap: 0.5rem;
}

.nav-logo {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 12px;
    height: 65px;
}

.model-selector {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: #333;
    padding: 0.25rem;
    border-radius: 4px;
}

.menu-button {
    background: #333;
    color: #fff;
    border: none;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    cursor: pointer;
    outline: none;
}

.menu-button.primary {
    background: #dc2626;
    color: #fff;
}

.menu-select {
    background: #333;
    color: #fff;
    border: none;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    outline: none;
}

.refresh-button {
    color: #fff;
    background: #333;
    border: none;
    padding: 0.25rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.refresh-button:hover {
    color: #f2d8bb;
}

/* Main Content */
.main-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 50px;
}

.chat-container {
    flex: 1;
    overflow-y: auto;
    padding: 1rem;
    background-color: #f0f0f0;
    background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M54.627 0l.83.828-1.415 1.415L51.8 0h2.827zM5.373 0l-.83.828L5.96 2.243 8.2 0H5.374zM48.97 0l3.657 3.657-1.414 1.414L46.143 0h2.828zM11.03 0L7.372 3.657 8.787 5.07 13.857 0H11.03zm32.284 0L49.8 6.485 48.384 7.9l-7.9-7.9h2.83zM16.686 0L10.2 6.485 11.616 7.9l7.9-7.9h-2.83zM22.343 0L13.857 8.485 15.272 9.9l7.9-7.9h-.83zm5.657 0L19.514 8.485 20.93 9.9l8.485-8.485h-1.415zM32.372 0L22.343 10.03 23.758 11.444l10.03-10.03h-1.415zm-5.657 0L18.686 8.03 20.1 9.444 28.13 1.414 26.714 0h-.002zm11.314 0L28.03 10.03 29.444 11.444 40.03 .828 38.614 0h-.002zM32.372 0L42.4 10.03 43.815 11.444 33.785 1.414 32.37 0h.002zm5.657 0L49.8 11.77l1.414-1.414L38.03 0h.002zM20.343 0L8.57 11.77l1.415 1.414L22.757 0h-2.414zm11.314 0L8.57 23.085l1.414 1.414L34.07 0h-2.414zm11.313 0L8.57 34.4l1.414 1.414L45.384 0h-2.414zM32.372 0L8.57 45.713l1.415 1.414L33.785 0h-1.413zm5.657 0L8.57 57.027l1.414 1.414L40.028 0h-2z' fill='%23dedede' fill-opacity='0.2' fill-rule='evenodd'/%3E%3C/svg%3E");
}

/* Messages */
.message {
    max-width: 85%;
    margin: 1rem 0;
    padding: 1rem;
    border-radius: 1rem;
    display: flex;
    gap: 1rem;
    align-items: start;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.message.user {
    margin-left: auto;
    background-color: #fab6b6;
    color: #1f2937;
    border: 1px solid rgba(0,0,0,0.1);
}

.message.assistant {
    margin-right: auto;
    background-color: white;
    border: 1px solid #e5e7eb;
    color: #1f2937;
}

.message-icon {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6b7280;
}

.message-icon i {
    font-size: 1.5rem;
}

/* Input Container */
.input-container {
    position: sticky;
    bottom: 0;
    background-color: white;
    border-top: 1px solid #e5e7eb;
    padding: 1rem;
    margin-top: auto;
}

.input-container textarea {
    width: 100%;
    resize: none;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    padding: 0.75rem;
    padding-right: 2.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    outline: none;
}

.input-container textarea:focus {
    border-color: #dc2626;
    box-shadow: 0 0 0 2px rgba(220, 38, 38, 0.1);
}

.input-container button {
    position: absolute;
    right: 1.5rem;
    bottom: 1.75rem;
    color: #6b7280;
    transition: color 0.2s;
}

.input-container button:hover {
    color: #dc2626;
}

.input-container button:disabled {
    color: #d1d5db;
    cursor: not-allowed;
}

/* Blocks Section */
.blocks-section {
    background: #fee2e2;
    display: flex;
    flex-direction: column;
    margin-top: 65px;
    height: 100vh;
    margin-left: 5px;
    margin-right: 5px;
}

.status-bar {
    background: #000000;
    color: #888;
    padding: 1rem;
    font-family: 'JetBrains Mono', monospace;
    font-size: 0.775rem;
    height: 150px;
    overflow: hidden;
    display: flex;
    margin: 1rem;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    border-radius: 8px;
    margin: 1rem;
    border: 1px solid #333;
    box-shadow: inset 0 0 10px rgba(0,0,0,0.5);
}

.status-line {
    line-height: 1.5;
    white-space: nowrap;
    width: 100%;
    text-align: left;
    color: #981b1b;
}

.status-line.current {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.status-line.current.waiting {
    color: #981b1b;
}

.status-line.current.error {
    color: #ff3333;
}

.status-line:not(.current) {
    opacity: 0.7;
}

.status-bar .fa-spinner {
    animation: spin 3s linear infinite;
}

.status-bar.processing .fa-spinner {
    animation: spin 1s linear infinite;
}

.blocks-container {
    flex: 1;
    overflow-y: auto;
    padding: 1rem;
}

.block-item {
    background: #ffffff;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    margin-bottom: 1rem;
    overflow: hidden;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
    max-height: 400px;
    display: flex;
    flex-direction: column;
}

.block-item-content {
    overflow-y: auto;
    flex: 1;
    min-height: 0;
    max-height: 350px;
}

/* Add specific styling for code blocks */
.block-item-content pre {
    margin: 0;
    height: 100%;
}

/* Add specific styling for HTML preview */
.block-item-content .html-preview {
    height: 100%;
    overflow-y: auto;
}

.block-item-header {
    background: #f9fafb;
    padding: 0.75rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e5e7eb;
    color: #981b1b;
}

.block-actions {
    display: flex;
    gap: 0.5rem;
}

.block-action-button {
    padding: 0.25rem;
    border-radius: 0.25rem;
    color: #6b7280;
    transition: color 0.2s;
    cursor: pointer;
    border: none;
    background: none;
}

.block-action-button:hover {
    color: #1f2937;
    background-color: #f3f4f6;
}

/* Modals */
.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
    align-items: center;
    justify-content: center;
}

.modal.show {
    display: flex;
}

/* Update modal styles */
.modal-content {
    background: white;
    border-radius: 0.5rem;
    width: 90vw;
    max-width: 1200px;
    max-height: 90vh;
    overflow-y: auto;
}

/* Add specific styling for screenshot modal */
#screenshot-modal .modal-content {
    width: 95vw;
    max-width: 1400px;
    background: #ffffff;
}

#screenshot-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

#screenshot-content img {
    max-width: 100%;
    max-height: calc(90vh - 100px);
    object-fit: contain;
    border-radius: 4px;
}

#screenshot-content img {
    transition: transform 0.3s ease;
    cursor: zoom-in;
}

#screenshot-content img.zoomed {
    transform: scale(1.5);
    cursor: zoom-out;
}

.modal-header {
    padding: 1rem;
    border-bottom: 1px solid #e5e7eb;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-body {
    padding: 1rem;
}

/* Settings specific styles */
.setting-group {
    margin-bottom: 1rem;
}

.setting-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
}

.setting-input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
}

.temperature-control {
    display: flex;
    align-items: center;
    gap: 1rem;
}

/* Chat List */
.chat-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.chat-item {
    padding: 0.75rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    cursor: pointer;
}

.chat-item:hover {
    background: #f3f4f6;
}

.chat-item.active {
    border-color: #dc2626;
    background: #fee2e2;
}

/* Toast Notifications */
.toast {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    color: white;
    animation: slideIn 0.3s ease-out;
    z-index: 50;
}

.toast.success { background-color: #059669; }
.toast.error { background-color: #dc2626; }

/* Token Display */
.token-display {
    position: fixed;
    bottom: 100px;
    left: 1rem;
    background: white;
    padding: 0.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    z-index: 10;
}

/* Animations */
@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

@keyframes slideIn {
    from { transform: translateX(100%); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
}

/* Scrollbar Styling */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #cbd5e1;
    border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
    background: #94a3b8;
}